'use strict';
import '../css/common.scss';
// import Swiper from 'swiper';

(function (window, document, $) {
    window.addEventListener('DOMContentLoaded', (event) => {

        var mainSwiper = new Swiper('.swiper-container', {
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });


    });

    $(function () {
        $(".menuBtn").on("click", function () {
            $(this).hasClass("js-open") ? ($(this).removeClass("js-open"),
                $("body").removeClass("js-open")) : ($(this).addClass("js-open"),
                    $("body").addClass("js-open"))
        });
        $(".menuNav").on("click", function () {
            $("body").removeClass("js-open");
            $(".menuBtn").removeClass("js-open")
        });
        var r = new SmoothScroll('a[href*="#"]')
            , u = {
                header: "[data-scroll-header]",
                speed: 1500,
                speedAsDuration: !0
            }
            , f = function (f) {
                var e = function (e) {
                    var o = e.target.closest(f), s;
                    o && o.tagName.toLowerCase() === "a" && (s = document.querySelector(o.hash),
                        s) && (e.preventDefault(),
                        document.body.classList.contains("active") == !0 && (_dataAsClick = _scrollfromTop,
                        document.body.classList.remove("active"),
                                $(".menuBtn").removeClass("active"),
                        document.documentElement.style.height = "100%",
                        document.body.style.top = "0px",
                        window.scrollTo(0, _dataAsClick)),
                            r.animateScroll(s, o, u))
                };
                window.addEventListener("click", e, !1)
            };
        f('a[href*="#"]')
    });


})(window, document, jQuery);